<template>
    <div class="repository-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.repositories') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.repositories'),
                                  disabled: true,
                                  href: 'breadcrumbs_repositories',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-tabs
                    color="accent"
                    center-active
                    show-arrows
                >
                 <!--   <v-tab href="#tab-mobile_parameters">{{ $t('repository.mobile_parameters') }}</v-tab> -->
                    <v-tab href="#tab-vehicle_accesses">{{ $t('repository.vehicle_accesses') }}</v-tab>
                    <v-tab href="#tab-trucks">{{ $t('repository.trucks') }}</v-tab>
                    <v-tab href="#tab-time_slots">{{ $t('repository.time_slots') }}</v-tab>
                    <v-tab href="#tab-silo_types">{{ $t('repository.silo_types') }}</v-tab>
                    <v-tab href="#tab-logistic_areas">{{ $t('repository.logistic_areas') }}</v-tab>
                    <v-tab href="#tab-equipments">{{ $t('repository.equipment') }}</v-tab>
                    <v-tab href="#tab-categories">{{ $t('repository.categories') }}</v-tab>
                    <v-tab href="#tab-delivery_report_states">{{ $t('repository.delivery_report_states') }}</v-tab>
                    <v-tab href="#tab-loading_report_states">{{ $t('repository.loading_report_states') }}</v-tab>
                    <v-tab href="#tab-signature_states">{{ $t('repository.signature_states') }}</v-tab>
                    <v-tab href="#tab-risks">{{ $t('repository.risks') }}</v-tab>
                    <v-tab href="#tab-instructions">{{ $t('repository.instructions') }}</v-tab>
                    <v-tab href="#tab-transporters">{{ $t('repository.transporters') }}</v-tab>
                    <v-tab href="#tab-expected_drivers">{{ $t('repository.expected_drivers') }}</v-tab>
                    <v-tab href="#tab-not_inventoryable_silos">{{ $t('repository.not_inventoryable_silos') }}</v-tab>
                    <v-tab href="#tab-vehicle_fleets">{{ $t('repository.vehicle_fleets') }}</v-tab>
                    <v-tab href="#tab-vehicle_conditionnings">{{ $t('repository.vehicle_conditionnings') }}</v-tab>
                    <v-tab href="#tab-cleaning-inside">{{ $t('repository.cleaning_inside') }}</v-tab>
                    <v-tab href="#tab-cleaning-outside">{{ $t('repository.cleaning_outside') }}</v-tab>

                    <v-tab-item :value="'tab-mobile_parameters'">
                        <mobile-parameters></mobile-parameters>
                    </v-tab-item>
                    <v-tab-item :value="'tab-vehicle_accesses'">
                        <vehicle-accesses></vehicle-accesses>
                    </v-tab-item>
                    <v-tab-item :value="'tab-trucks'">
                        <trucks></trucks>
                    </v-tab-item>
                    <v-tab-item :value="'tab-time_slots'">
                        <time-slots></time-slots>
                    </v-tab-item>
                    <v-tab-item :value="'tab-silo_types'">
                        <silo-types></silo-types>
                    </v-tab-item>
                    <v-tab-item :value="'tab-logistic_areas'">
                        <logistic-areas></logistic-areas>
                    </v-tab-item>
                    <v-tab-item :value="'tab-equipments'">
                        <equipment></equipment>
                    </v-tab-item>
                    <v-tab-item :value="'tab-categories'">
                        <categories></categories>
                    </v-tab-item>
                    <v-tab-item :value="'tab-delivery_report_states'">
                        <delivery_report_states></delivery_report_states>
                    </v-tab-item>
                    <v-tab-item :value="'tab-loading_report_states'">
                        <loading_report_states></loading_report_states>
                    </v-tab-item>
                    <v-tab-item :value="'tab-signature_states'">
                        <signature_states></signature_states>
                    </v-tab-item>
                    <v-tab-item :value="'tab-risks'">
                        <risks></risks>
                    </v-tab-item>
                    <v-tab-item :value="'tab-instructions'">
                        <instructions></instructions>
                    </v-tab-item>
                    <v-tab-item :value="'tab-transporters'">
                        <transporters></transporters>
                    </v-tab-item>
                    <v-tab-item :value="'tab-expected_drivers'">
                        <expected_drivers></expected_drivers>
                    </v-tab-item>
                    <v-tab-item :value="'tab-not_inventoryable_silos'">
                        <not_inventoryable_silos></not_inventoryable_silos>
                    </v-tab-item>
                    <v-tab-item :value="'tab-vehicle_fleets'">
                        <vehicle-fleets></vehicle-fleets>
                    </v-tab-item>
                    <v-tab-item :value="'tab-vehicle_conditionnings'">
                        <vehicle-conditionnings></vehicle-conditionnings>
                    </v-tab-item>
                    <v-tab-item :value="'tab-cleaning-inside'">
                        <inside-cleaning/>
                    </v-tab-item>
                    <v-tab-item :value="'tab-cleaning-outside'">
                        <outside-cleaning/>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import MobileParameters from "../components/Repository/MobileParameters";
    import VehicleAccesses from "../components/Repository/VehicleAccesses";
    import Trucks from "../components/Repository/Trucks";
    import TimeSlots from "../components/Repository/TimeSlots";
    import SiloTypes from "../components/Repository/SiloTypes";
    import LogisticAreas from "../components/Repository/LogisticAreas";
    import Equipment from "../components/Repository/Equipment";
    import Categories from "../components/Repository/Categories";
    import Delivery_report_states from "../components/Repository/DeliveryReportStates";
    import Loading_report_states from "../components/Repository/LoadingReportState";
    import Signature_states from "../components/Repository/SignatureStates";
    import Risks from "../components/Repository/Risks";
    import Instructions from "../components/Repository/Instructions";
    import Transporters from "../components/Repository/Transporters";
    import Expected_drivers from "../components/Repository/ExpectedDrivers";
    import Not_inventoryable_silos from "../components/Repository/NotInventoryableSilos";
    import VehicleFleets from "../components/Repository/VehicleFleets";
    import VehicleConditionnings from "../components/Repository/VehicleConditionnings";
    import InsideCleaning from "../components/Repository/InsideCleaning";
    import OutsideCleaning from "../components/Repository/OutsideCleaning";

    export default {
        name: "repository",
        components: {
            Expected_drivers,
            Signature_states,
            Loading_report_states,
            Delivery_report_states,
            Categories,
            Equipment,
            LogisticAreas,
            SiloTypes,
            TimeSlots,
            MobileParameters,
            VehicleAccesses,
            Trucks,
            Risks,
            Instructions,
            Transporters,
            Not_inventoryable_silos,
            VehicleFleets,
            VehicleConditionnings,
            InsideCleaning,
            OutsideCleaning
        },
    }
</script>

<style lang="scss">

.repository-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
