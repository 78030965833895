var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"table",attrs:{"custom-sort":_vm.customSort,"footer-props":{
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                },"headers":_vm.headers,"items":_vm.indexedHistories,"loading":_vm.loading,"loading-text":_vm.$t('global.loading_text'),"no-data-text":_vm.$t('global.no_data'),"no-results-text":_vm.$t('global.no_results_text'),"item-key":"index","sort-by":"date"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getOperationDate(item),'L')))])]}},{key:"item.start_time",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getRealStartTime(item),'LT')))])]}},{key:"item.end_time",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getRealEndTime(item),'LT')))])]}},{key:"item.driver",fn:function(ref){
                var item = ref.item;
return [(item.user)?_c('span',[_vm._v(" "+_vm._s(item.user))]):_c('span',[_vm._v(" "+_vm._s(item.driver))])]}},{key:"item.vehicle",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.truck)+" ")])]}},{key:"item.order",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.order_id)+" - "+_vm._s(item.line_id)+" ")])]}},{key:"item.product",fn:function(ref){
                var item = ref.item;
return [(item.category.media[0])?_c('span',[_c('img',{attrs:{"alt":_vm.$t('repository.pictogram'),"src":("storage/" + (item.category.media[0].id) + "/" + (item.category.media[0].name)),"height":"32"}})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getProduct(item))+" ")])]}},{key:"item.delivered_quantity",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getQuantity(item))+" ")])]}},{key:"item.silo",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSilo(item))+" ")])]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getType(item))+" ")])]}},{key:"item.scanned",fn:function(ref){
                var item = ref.item;
return [(item.scanned !== null)?_c('div',[_c('v-icon',{attrs:{"color":item.scanned ? 'green': 'orange'}},[_vm._v("mdi-barcode-scan\n                ")])],1):_vm._e()]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }