<template>
    <div class="drivers-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.drivers') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.drivers'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="mb-2" color="primary" dark>{{ $t('driver.new') }}</v-btn>
                            </template>
                            <v-card
                                center
                            >
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field
                                            v-model="editedItem.email"
                                            v-validate="'required|email'"
                                            :error-messages="errors.collect('email')"
                                            :label="$t('driver.email')"
                                            data-vv-name="email"
                                            name="email"
                                            prepend-icon="mdi-email"
                                            type="text"
                                        ></v-text-field>
                                        <v-text-field
                                            id="name"
                                            v-model="editedItem.name"
                                            v-validate="'required|min:3'"
                                            :error-messages="errors.collect('name')"
                                            :label="$t('driver.name')"
                                            data-vv-name="name"
                                            name="name"
                                            prepend-icon="mdi-account"
                                            type="text"
                                        ></v-text-field>
                                        <v-autocomplete
                                            id="roles"
                                            v-model="editedItem.roles"
                                            :items="roles"
                                            :label="$t('user.roles')"
                                            chips
                                            data-vv-name="roles"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            name="roles"
                                            prepend-icon="mdi-chess-queen"
                                            required

                                        >
                                            <template v-slot:item="{ item, attrs, on}">
                                                <v-list-item v-bind="attrs" v-on="on" #default="{ active }">
                                                    <v-list-item-action>
                                                        <v-checkbox :input-value="active"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        {{ $t(`role.${item.name}`) }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot:selection="data">
                                                <v-chip>{{ $t(`role.${data.item.name}`) }}</v-chip>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            id="logistic_areas"
                                            v-model="editedItem.logistic_areas"
                                            v-validate="'required'"
                                            :error-messages="errors.collect('logistic_areas')"
                                            :filter="filterAreas"
                                            :items="logistic_areas"
                                            :label="$t('driver.logistic_areas')"
                                            :search-input.sync="searchAreasInput"
                                            chips
                                            data-vv-name="logistic_areas"
                                            item-text="code"
                                            item-value="id"
                                            multiple
                                            name="logistic_areas"
                                            prepend-icon="mdi-crosshairs"
                                            @blur="getTrucks(editedItem.logistic_areas)"
                                        >
                                            <v-list
                                                v-if="isSelectAllAreasAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon :color="editedItem.logistic_areas.length > 0 ? 'primary' : ''">{{ iconAreas }}</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template v-slot:selection="data">
                                                <v-chip class="mx-1">
                                                    {{ data.item.code }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.code }} ({{ data.item.name }})
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            id="trucks"
                                            v-model="editedItem.trucks"
                                            v-validate="'required'"
                                            :disabled="trucks_from_logistic_areas.length <= 0"
                                            :error-messages="errors.collect('trucks')"
                                            :filter="filterTrucks"
                                            :items="trucks_from_logistic_areas"
                                            :label="$t('driver.trucks')"
                                            :search-input.sync="searchTrucksInput"
                                            chips
                                            data-vv-name="trucks"
                                            item-text="code"
                                            item-value="id"
                                            multiple
                                            name="trucks"
                                            prepend-icon="mdi-tanker-truck"
                                        >
                                        </v-autocomplete>
                                        <v-autocomplete
                                            id="expected_driver"
                                            v-model="editedItem.driver_id"
                                            :disabled="unused_expected_drivers.length <= 0"
                                            :error-messages="errors.collect('driver')"
                                            :filter="filterDriver"
                                            :items="unused_expected_drivers"
                                            :label="$t('driver.expected_driver')"
                                            chips
                                            data-vv-name="driver"
                                            item-text="code"
                                            item-value="id"
                                            name="expected_driver"
                                            prepend-icon="mdi-truck"
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip class="mx-1">
                                                    {{ data.item.code }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.name }} ({{ data.item.code }})
                                            </template>
                                        </v-autocomplete>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">{{ $t('driver.cancel') }}</v-btn>
                                    <v-btn :disabled="submit_button_is_disabled" @click="save">
                                        {{ formTitle }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            :label="$t('driver.search')"
                            append-icon="mdi-magnify"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': [10,20,100],
                        'items-per-page-text': $t('global.rows_per_page')}"
                        :headers="headers"
                        :items="drivers"
                        :items-per-page="15"
                        :loading="loading"
                        :loading-text="$t('global.loading_text')"
                        :no-data-text="$t('global.no_data')"
                        :no-results-text="$t('global.no_results_text')"
                        :options.sync="options"
                        :search="search"
                        :server-items-length="totalDrivers"
                        class="elevation-1"
                        multi-sort
                    >
                        <template v-slot:item.logistic_areas="{ item }">
                            {{ item.logistic_areas.length }}
                        </template>
                        <template v-slot:item.trucks="{ item }">
                            <v-chip v-for="truck in item.trucks" :key="truck.id" class="mx-1">
                                {{ truck.code }}
                            </v-chip>
                        </template>
                        <template v-slot:item.driver="{ item }">
                            <v-chip v-if="!!item.driver" class="mx-1">
                                {{ item.driver.code }}
                            </v-chip>
                        </template>
                        <template v-slot:item.last_login_date="{ item }">
                            {{ item.last_login_date | moment('L LT') }}
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment('L LT') }}
                        </template>
                        <template v-slot:item.updated_at="{ item }">
                            {{ item.updated_at | moment('L LT') }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon
                                :title="$t('driver.edit')"
                                class="mr-2"
                                small
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <!-- Retrait à la demande de AVRIL 17/06/2020
                           <v-icon
                               small
                               :title="$t('driver.delete')"
                               @click="deleteItem(item)"
                           >
                               mdi-delete
                           </v-icon>
                           -->
                       </template>
                   </v-data-table>
               </v-card>
           </v-col>
       </v-row>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('driver.sure') }}</v-card-title>
               <v-card-text>{{ $t('driver.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('driver.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteDriver()">
                       {{ $t('driver.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
       <v-snackbar
           v-model="snackbar"
           :color="snackbar_type"
           top
       >
           {{ snackbar_text }}
           <v-btn
               text
               @click="snackbar = false"
           >
               Close
           </v-btn>
       </v-snackbar>
   </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import _ from 'lodash'
import {AREA_TRUCKS, AREAS, DRIVERS, ROLE, ROLES, USERS} from "../api";

export default {
    name: "driver",
    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            headers: [
                {text: this.$t('driver.id'), value: 'id', sortable: false},
                {text: this.$t('driver.name'), value: 'name', sortable: true},
                {text: this.$t('driver.email'), value: 'email', sortable: true},
                {text: this.$t('driver.logistic_areas'), value: 'logistic_areas', sortable: false},
                {text: this.$t('driver.trucks'), value: 'trucks', sortable: false},
                {text: this.$t('driver.expected_driver'), value: 'driver', sortable: false},
                {text: this.$t('driver.last_app_version'), value: 'last_app_version', sortable: true},
                {text: this.$t('driver.last_used_truck'), value: 'last_used_truck', sortable: true},
                {text: this.$t('driver.last_login_date'), value: 'last_login_date', sortable: true},
                {text: this.$t('driver.created_at'), value: 'created_at', sortable: false},
                {text: this.$t('driver.updated_at'), value: 'updated_at', sortable: false},
                {text: this.$t('driver.actions'), value: 'action', sortable: false},
            ],
            loading: true,
            options: {},
            page: 1,
            totalDrivers: 0,
            drivers: [],
            search: '',
            logistic_areas: [],
            toggleAreas: null,
            searchAreasInput: '',
            trucks_from_logistic_areas: [],
            toggleTrucks: null,
            searchTrucksInput: '',
            expected_drivers: [],
            editedItem: {
                email: '',
                name: '',
                trucks: [],
                logistic_areas: [],
                driver_id: null,
            },
            editedIndex: -1,
            defaultItem: {
                email: '',
                name: '',
                trucks: [],
                logistic_areas: '',
                driver_id: null,
            },
            dialog: false,
            dialogDelete: false,
            submit_button_is_disabled: false,
            driverToDelete: null,
            role_driver_id: null,
            roles: [],
            base_roles: []
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? this.$t('driver.new') : this.$t('driver.edit')
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        allAreas() {
            return this.editedItem.logistic_areas.length === this.logistic_areas.length
        },
        someAreas() {
            return this.editedItem.logistic_areas.length > 0 && !this.allAreas
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        /**
         * Get all expected drivers not already linked with a user (except the one of edited item)
         */
        unused_expected_drivers() {
            const {id: editedItemId = null} = this.editedItem || {}
            return this.expected_drivers
                .filter(o => !o.user_id || o.user_id === editedItemId)
                .sort(({name: nameA}, {name: nameB}) => (nameA || '').localeCompare(nameB || ''))
        }
    },
    mounted() {
        this.getExpectedDrivers();
        this.getRoleDriver();
        this.getRoles();
        this.getLogisticAreas();
    },
    methods: {
        /**
         * Get roles from API
         */
        getRoles() {
            this.axios.get(ROLES).then((success) => {
                this.roles = success.data.filter(role => role.name === 'driver' || role.name === 'transporter');
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },
        /**
         * Filter areas drop-down
         */
        filterAreas(item, queryText) {
            let textOne = "";
            let textTwo = "";
            let searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        /**
         * Filter trucks drop-down
         */
        filterTrucks(item, queryText) {
            let text = "";
            let searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        /**
         * Filter expected driver drop-down
         */
        filterDriver({code, name}, queryText) {
            const searchText = queryText.toLowerCase();
            return (code || '').toLowerCase().indexOf(searchText) > -1 || (name || '').toLowerCase().indexOf(searchText) > -1;
        },
        /**
         * Get areas from API
         */
        getLogisticAreas() {
            this.axios.get(AREAS).then((success) => {
                this.logistic_areas = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },
        /**
         * Get trucks from API
         * @param logistic_areas {array}
         */
        getTrucks(logistic_areas) {
            if (logistic_areas.length > 0) {
                this.axios.get(
                    AREA_TRUCKS,
                    {
                        params: {
                            logistic_areas
                        },
                    }
                ).then((success) => {
                    this.trucks_from_logistic_areas = success.data;
                    this.editedItem.trucks = _.intersection(
                        _.map(this.trucks_from_logistic_areas, 'id'),
                        this.editedItem.trucks
                    );
                }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   })
               }
           },
           /**
            * Get expected drivers
            */
           getExpectedDrivers() {
               this.loading = true;
               this.axios.get(DRIVERS).then(success => {
                   this.expected_drivers = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get role driver
            */
           getRoleDriver() {
               this.axios.get(this.replacePathParams(ROLE, {role: 'driver'})).then((success) => {
                   this.role_driver_id = success.data.id;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               })
           },
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get drivers from API
            */
           getDrivers() {
               this.loading = true;

               const {sortBy, sortDesc, page, itemsPerPage} = this.options;

               let sort = '';
               if (Array.isArray(sortBy)) {
                   _.each(sortBy, (elem, key) => {
                       let order = sortDesc[key] ? '-' : '';
                       sort += order + elem + ',';
                   })
               } else {
                   let order = sortDesc ? '-' : '';
                   sort += order + sortBy;
               }

               this.axios.get(
                   USERS,
                   {
                       params: {
                           page,
                           per_page: itemsPerPage,
                           sort,
                           "filter[anywhere]": this.search,
                           "filter[roles]": "driver",
                           "include": "driver,logistic_areas,trucks",
                       }
                   }
               ).then((success) => {
                   this.drivers = success.data.data;
                   this.totalDrivers = success.data.total;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           editItem(driver) {
               let tmpTrucks = Object.assign({}, driver.trucks);
               let tmpAreas = Object.assign({}, driver.logistic_areas);
               let tmpRoles = Object.assign({}, _.toArray(driver.roles).filter(role => (role.name === 'driver' || role.name === 'transporter')));
               let tmpDriver = Object.assign({}, driver.driver);
               this.base_roles = _.toArray(driver.roles).filter(role => (role.name !== 'driver' && role.name !== 'transporter')).map(item => item.id)
               driver.logistic_areas = _.map(driver.logistic_areas, (area) => {
                   return area.id
               });
               driver.roles = _.map(tmpRoles, (role) => {
                   return role.id
               });
               this.getTrucks(driver.logistic_areas);
               driver.trucks = _.map(driver.trucks, (truck) => {
                   return truck.id
               });
               driver.driver_id = !tmpDriver ? null : tmpDriver.id;
               this.editedIndex = this.drivers.indexOf(driver);
               this.editedItem = Object.assign({}, driver);
               this.dialog = true;
               driver.trucks = tmpTrucks;
               driver.logistic_areas = tmpAreas;
               driver.roles = tmpRoles;
               driver.driver = tmpDriver;
           },
           deleteItem(driver) {
               this.driverToDelete = driver;
               this.dialogDelete = true;
           },
           deleteDriver() {
               this.axios.delete(
                   USERS + '/' + this.driverToDelete.id,
               ).then(() => {
                   this.drivers.splice(this.drivers.indexOf(this.driverToDelete), 1);
                   this.showSnackbar('success', this.$t('user.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               this.editedItem = Object.assign({}, this.defaultItem);
               this.editedIndex = -1
           },
           save() {
               this.submit_button_is_disabled = true;
               // si les champs sont undefined à cause du clear, ils ne sont pas envoyés ...
               this.editedItem.driver_id = this.editedItem.driver_id || null

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           updateItem(driver) {
               driver.roles = [...driver.roles, ...this.base_roles]
               this.axios.patch(
                   USERS + '/' + driver.id,
                   driver
               ).then((success) => {
                   Object.assign(this.drivers[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('driver.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           createItem(driver) {
               driver = {...driver, ...{roles: [this.role_driver_id]}};
               this.axios.post(
                   USERS,
                   driver
               ).then((success) => {
                   this.drivers.push(success.data);
                   this.showSnackbar('success', this.$t('driver.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           }
       }
       ,
       watch: {
           options: {
               handler() {
                   this.getDrivers();
               }
               ,
               deep: true,
           },
           search: _.debounce(function () {
               this.getDrivers();
           }, 500),
           dialog(val) {
               val || this.close()
           },
           /**
            * Select all areas or not
            */
           toggleAreas () {
               this.$nextTick(() => {
                   if (this.allAreas) {
                       this.editedItem.logistic_areas = []
                   } else {
                       this.logistic_areas.forEach(element => {
                           if (!this.editedItem.logistic_areas.includes(element.id)) {
                               this.editedItem.logistic_areas.push(element.id);
                           }
                       });
                   }
               })
           },
       }
   }
</script>

<style lang="scss">

.drivers-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
