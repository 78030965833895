<template>
    <div>
        <v-card-title class="d-flex align-start">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="1">
                    <v-btn
                        class="ml-4"
                        color="primary"
                        @click="dialog = true"
                    >
                        {{ $t('site.new') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-data-table
            ref="databaseAction"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="filteredActions"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            sort-by="created_at"
            sort-desc
        >
            <template v-slot:item.created_at="{ item }">
                <span v-if="item.created_at">
                    {{ item.created_at | moment('L LT') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.user="{ item }">
                <span v-if="item.user">
                    {{ item.user.name || $t('site.no_data') }}
                </span>
                <span v-else>
                    {{ item.user_avril_id || $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.type.description="{ item }">
                <span v-if="item.type">
                    <v-chip
                        :color="getTypeColor(item.type.name)"
                        class="ma-2"
                        label
                        text-color="white"
                    >
                         {{ item.type.description || $t('site.no_data') }}
                    </v-chip>
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.security_level.color="{ item }">
                <img v-if="item.history && item.history.security_level" :src="'images/' + getSecurityLevelImage(item.history.security_level.color)">
            </template>
            <template v-slot:item.history_silos_media="{ item }">
                <span v-if="item.history">
                    <span v-for="history in item.history.history_silos">
                        <div v-if="history.media">
                            <img
                                :alt="$t('repository.pictogram')"
                                :src="`storage/${history.media[0].id}/${history.media[0].name}`"
                                height="32"
                            >
                        </div>
                    </span>
                </span>
            </template>
            <template v-slot:item.details="{ item }">
                <span v-for="line in replaceBrTags(item.details).split('\n')" v-if="item.details">
                    {{ line }} <br/>
                </span>
            </template>
            <template v-slot:item.silo="{ item }">
                <span v-if="item.silo">
                    {{ item.silo.code || $t('site.no_data') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>

            <template v-slot:item.email_send="{ item }">
                <span v-if="item.email_send">
                    {{ format_mail(item.email_send) }}
                </span>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('action.new') }}</v-card-title>
                <v-card-text>
                    <v-form name="sites">

                        <v-select
                            id="action.type"
                            v-model="action.type_id"
                            v-validate="'required'"
                            :items="this.types"
                            :label="$t('action.type')"
                            data-vv-name="states"
                            item-text="name"
                            item-value="id"
                            name="action.type"
                            prepend-icon="mdi-tag-text"
                            required
                        >
                            <template slot="item" slot-scope="data">
                                {{ data.item.name }} : {{ data.item.description }}
                            </template>

                            <template slot="selection" slot-scope="data">
                                {{ data.item.name }} : {{ data.item.description }}
                            </template>
                        </v-select>

                        <v-textarea
                            v-model="action.details"
                            :label="$t('action.details')"
                            name="input-7-4"
                            outlined
                            prepend-icon="mdi-comment-text-multiple-outline"
                            rows="5"
                        ></v-textarea>

                        <v-switch
                            v-model="action.notification_sent"
                            :label="$t('action.notify')"
                            class="ma-2"
                        />

                        <div v-if="action.notification_sent">
                            <v-checkbox
                                v-if="sales_representative_email"
                                v-model="emailsToNotify"
                                :value="sales_representative_email"
                            >
                                <template slot="label">
                                    <span class="font-weight-bold">Commercial :&nbsp;</span> {{
                                    sales_representative_email }}
                                </template>
                            </v-checkbox>
                            <span v-for="email in emails">
                                <span class="font-weight-bold">{{ email.name }}</span>

                                <v-checkbox
                                    v-if="email.logistic_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.logistic_manager_email"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold">Responsable logistique :&nbsp;</span> {{
                                            email.logistic_manager_email
                                        }}
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.quality_safety_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.quality_safety_manager_email"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold">Responsable qualité/sécurité :&nbsp;</span> {{
                                            email.quality_safety_manager_email
                                        }}
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.sales_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.sales_manager_email"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold">{{ $t('repository.sales_manager') }}</span> {{
                                            email.sales_manager_email
                                        }}
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.sandra_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.sandra_manager_email"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold">Référent ZL :&nbsp;</span> {{
                                            email.sandra_manager_email
                                        }}
                                    </template>
                                </v-checkbox>
                            </span>

                            <v-textarea
                                v-model="additionalMails"
                                hint="Séparer les mails par un point virgule ( ; )"
                                label='Adresses mails supplémentaires séparés par des ";"'
                                outlined
                                rows="2"
                            >
                            </v-textarea>
                        </div>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" text @click="dialog= false">
                                {{ $t('site.cancel') }}
                            </v-btn>
                            <v-btn :disabled="updating" color="warning darken-1"
                                   text @click="addAction()">
                                {{ $t('action.add') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {ACTIONS, MANUAL_ACTION_TYPES} from "../../api";

export default {
    name: "actions",
    props: {
        silo_selected: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            headers: [
                    {text: this.$t('action.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('action.type_description'), value: 'type.description', sortable: true},
                    {
                        text: this.$t('action.silo'), value: 'silo', sortable: true, sort: (a, b) => {
                            return (a.code > b.code) ? 1 : -1;
                        }
                    },
                    {text: this.$t('action.user'), value: 'user', sortable: true},
                    {text: this.$t('action.security_level'), value: 'security_level.color', sortable: false},
                    {text: this.$t('action.history_risks'), value: 'history_silos_media', sortable: false},
                    {text: this.$t('action.details'), value: 'details', sortable: true},
                    {text: this.$t('action.send_to'), value: 'email_send', sortable: true, width: '100px'},
                ],
                dialog: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                types: [],
                updating: false,
                additionalMails: null,
                emailsToNotify: [],
                actions: [],
                action: {
                    site_id: null,
                    type_id: null,
                    details: null,
                    silo_id: null,
                    notification_sent: false,
                    is_manual: true,
                },
                site: {},
                switchNotifEnabled: false
            }
        },
        async mounted() {
            this.site = this.silo_selected.site;
            this.action.site_id = this.site.id;
            this.action.silo_id = this.silo_selected.id;
            await this.getManualActionTypes();
            this.switchNotifEnabled = true;
        },
        methods: {
            mergeMails() {
                const mails = this.additionalMails === null ? [] : this.additionalMails.split(';');
                mails.forEach(mail => this.emailsToNotify.push(mail));
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            getTypeColor(name) {
                switch (name) {
                    case "Sécurité":
                        return "#e68e22";
                    case "Exécution":
                        return "#631f26";
                    case "Admin":
                        return "#747577";
                    default:
                        return "";
                }
            },
            /**
             * Get the action types
             *
             * @returns {Promise<void>}
             */
            async getManualActionTypes() {
                await this.axios.get(MANUAL_ACTION_TYPES).then(({data: types}) => {
                    this.types = types;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            addAction() {
                this.updating = true;
                if (this.action.notification_sent) {
                    this.mergeMails();
                    this.action.email_send = _.join(this.emailsToNotify, ';');
                }
                this.axios.post(ACTIONS, this.action)
                .then(({data: action}) => {
                    this.silo_selected.actions.push(action);
                    this.$nextTick(() => {
                        this.$forceUpdate()
                    })
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.$emit('action-added')
                    this.updating = false;
                    this.dialog = false;
                    this.reset_action();
                });
            },
            /**
             * Method used to format mails
             *
             * @param mails
             */
            format_mail(mails) {
                return mails.split(';').join('\n')
            },
            /**
             * Method used to reset action
             */
            reset_action() {
                this.action.details = null;
                this.action.type_id = null;
                this.action.notification_sent = false;
                this.action.email_send = null;
                this.action.is_manual = true;
                this.additionalMails = null;
                this.setDefaultEmailsToNotify();
            },
            setDefaultEmailsToNotify() {
                this.emails.forEach(email => {
                    if (email.quality_safety_manager_email) {
                        this.emailsToNotify.push(email.quality_safety_manager_email);
                    }
                    if (email.logistic_manager_email) {
                        this.emailsToNotify.push(email.logistic_manager_email);
                    }
                    if (email.sandra_manager_email) {
                        this.emailsToNotify.push(email.sandra_manager_email);
                    }
                    if (email.sales_manager_email) {
                        this.emailsToNotify.push(email.sales_manager_email);
                    }
                });
            },
            setDefaultSalesEmailsToNotify() {
                if (this.sales_representative_email) {
                    this.emailsToNotify.push(this.sales_representative_email);
                }
            },
            replaceBrTags(item) {
                if (item) {
                    return item?.replace(/<br\s*[\/]?>/gi, "\n");
                }

                return "";
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            }
        },
        computed: {
            filteredActions() {
                this.silo_selected.actions = _.orderBy(this.silo_selected.actions, 'created_at', 'desc');
                return this.silo_selected.actions.filter(action => action.type?.is_visible === 1);
            },
            sales_representative_email() {
                let sales = null;
                if (this.action.silo_id) {
                    sales = this.site.silos.find(silo => silo.id === this.action.silo_id).sales_representative_email;
                }

                return sales;
            },
            emails() {
                let emails = [];

                this.site?.logistic_areas?.forEach(logistic_area => {
                    emails.push({
                        name: logistic_area.name,
                        logistic_manager_email: logistic_area.logistic_manager_email,
                        quality_safety_manager_email: logistic_area.quality_safety_manager_email,
                        sandra_manager_email: logistic_area.sandra_manager_email,
                        sales_manager_email: logistic_area.sales_manager_email,
                    });
                });

                _.forEach(emails, function (object) {
                    Object.keys(object).forEach((key) => (object[key] == null) && delete object[key]);

                    var keys = Object.keys(object);
                    if (keys.length === 1) {
                        delete emails[emails.indexOf(object)];
                    }
                });

                return emails.filter(function (email) {
                    return email != null;
                });
            }
        },
        watch: {
            emails: {
                handler: "setDefaultEmailsToNotify",
                immediate: true,
            },
            sales_representative_email: {
                handler: "setDefaultSalesEmailsToNotify",
                immediate: true,
            },
        }
    }
</script>
